import React, { useState } from 'react';
import { ListBase, useTranslate } from 'react-admin';
import DatagridCustom from '../../Components/DatagridCustom/DatagridCustom';
import dayjs from 'dayjs';
import TextFieldBase from '../../Components/CustomFields/TextFieldBase/TextFieldBase';
import CardDefault from '../../Components/CardDefault/CardDefault';
import ActionsListTransactions from '../../Components/ActionsListTransactions/ActionsListTransactions';
import TopToolbarList from '../../Components/TopToolbarList/TopToolbarList';
import { FaBtc } from 'react-icons/fa';
import FiltersList from '../../Components/FiltersList/FiltersList';
import LoadingCustom from '../../Components/LoadingCustom/LoadingCustom';
import { useSelector } from 'react-redux';
import PaginationCustom from '../../Components/PaginationCustom/PaginationCustom';
import CustomEmpty from '../../Components/CustomEmpty/CustomEmpty';
import TextFieldRefund from '../../Components/CustomFields/TextFieldRefund/TextFieldRefund';
import useCopyClipboard from '../../Hooks/useCopyClipboard';
import { formatMount } from '../../helpers/multiuso';
import WrapperComponent from '../../Components/WrapperComponent/WrapperComponent';
import { Box, Typography } from '@mui/material';

const formatTime = (time) => {
  return dayjs(time).format('HH:mm');
};

const refundedFormatMount = (record, _currency) => {
  const currency = _currency.toLowerCase();

  if (currency === 'ars') {
    if (record.refunded_amount) { //si hay un refunded tengo que restarle el refund del monto
      const mountARS = Math.trunc(record[currency]);
      const refunded = (mountARS - record.refunded_amount);
      return refunded > 0 ? formatMount(refunded, currency) : '0';
    } else {
      return formatMount(record[currency], currency);
    }
  } else { //si esta en dolares tengo que convertir el refunded a dolares
    if (record.refunded_amount) {
      const mountUSD = record.refunded_amount / record.usd_ars;
      const refunded = Math.trunc(record[currency] - mountUSD);
      return refunded > 0 ? formatMount(refunded, currency) : 0;
    } else {
      return formatMount(record[currency], currency);
    }
  }

};

const ListTransactions = () => {
  const { selectedBranch } = useSelector(state => state.branch);
  const { selectedMerchant } = useSelector(state => state.merchant);
  const { currency } = useSelector(state => state.config);
  const { date, min_mount, max_mount, refunded } = useSelector(state => state.filter);

  const translate = useTranslate();
  const copy = useCopyClipboard();

  const copyClipHash = (hash) => {
    copy(hash);
  };

  return (
    <WrapperComponent>
      {
        !selectedMerchant ? (
          <Box sx={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography>{translate('no_selected_merchant_branch')}</Typography>
          </Box>
        ) : selectedBranch?.id ? (
          <ListBase
            filter={{ date: date.format('YYYY-MM-DD'), min_ars: min_mount, max_ars: max_mount, refunded }}
            emptyWhileLoading
          >
            <TopToolbarList title={translate('activity')} icon={<FaBtc size={33} style={{ transform: 'rotate(-18deg)' }} />} />

            <FiltersList />

            <DatagridCustom
              size='small'
              noPadding
              // rowStyle={rowStyle()}
              empty={<CustomEmpty title={'empty_transactions'} />}
            >

              <TextFieldRefund
                small
                source={currency == 'ARS' ? 'ars' : 'usd'}
                label={translate('mount')}
                fontSize='14px'
                variant='primary'
                formatRecord={(record) => `${refundedFormatMount(record, currency)}`}
                sortable={false}
              />

              <CardDefault
                source={translate('time')}
                small
                style={{
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  padding: '0 50px'
                }}
                sortable={false}
              >
                <TextFieldBase fontSize='13px' source={'time'} label={translate('time')} formatRecord={formatTime} />
              </CardDefault>

              <CardDefault
                source={translate('activity_resource.pay_id')}
                small
                style={{
                  width: '300px',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  padding: '0 30px'
                }}
                sortable={false}
              >
                <TextFieldBase
                  fontSize='13px'
                  source={'hash'}
                  style={{ direction: 'rtl', color: '#cccccc', cursor: 'pointer' }}
                  onClick={copyClipHash}
                />
              </CardDefault>

              <CardDefault small style={{ marginLeft: '12px' }}>
                <ActionsListTransactions />
              </CardDefault>

            </DatagridCustom>

            <PaginationCustom />
          </ListBase>
        ) : (
          <LoadingCustom />
        )
      }
    </WrapperComponent>
  );
};

export default ListTransactions;