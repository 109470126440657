export const formatNumber = (n) => {
  if (n >= 1000000000) {
    return Math.floor(n / 1000000000) + ' MM';
  } else if (n >= 1000000) {
    return Math.floor(n / 1000000) + 'M';
  } else if (n >= 1000) {
    return Math.floor(n / 1000) + 'k';
  }else {
    return n.toString();
  }
};

function addThousandsSeparator(number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

export const formatMount = (number, currency) => {
  if(currency?.toLowerCase() === 'ars'){
    return number.toLocaleString('es-ar', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 2
    });
  }else{
    return parseFloat(number.toFixed(2)).toLocaleString('en-EN', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2
    });
  }
};